import { DepartmentFilterChip } from '@/src/components/features/FilterChip/DepartmentFilterChip';
import { FacilityTypeFilterChip } from '@/src/components/features/FilterChip/FacilityTypeFilterChip';
import { MedicalInstitutionLabelFilterChipUsingSearchFormat } from '@/src/components/features/FilterChip/MedicalInstitutionLabelFilterChipUsingSearchFormat';
import { TagFilterChip } from '@/src/components/features/FilterChip/TagFilterChip';

import styles from './styles.module.scss';

type Props = {
  facilityType: Omit<
    React.ComponentProps<typeof FacilityTypeFilterChip>,
    'size'
  >;
  department: Omit<React.ComponentProps<typeof DepartmentFilterChip>, 'size'>;
  tag: Omit<React.ComponentProps<typeof TagFilterChip>, 'size'>;
  isTagFeatureAvailable: boolean;
  label: Omit<
    React.ComponentProps<
      typeof MedicalInstitutionLabelFilterChipUsingSearchFormat
    >,
    'size'
  >;
  isMedicalInstitutionLabelFeatureAvailable: boolean;
};

export const SearchFilterPresenter: React.FC<Props> = (props) => {
  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.text}>絞り込み</div>
        <div className={styles.chipWrapper}>
          <div className={styles.chip}>
            <FacilityTypeFilterChip {...props.facilityType} size="medium" />
          </div>
          <div className={styles.chip}>
            <DepartmentFilterChip {...props.department} size="medium" />
          </div>
          {props.isTagFeatureAvailable && (
            <div className={styles.chip}>
              <TagFilterChip {...props.tag} size="medium" />
            </div>
          )}
          {props.isMedicalInstitutionLabelFeatureAvailable && (
            <div className={styles.chip}>
              <MedicalInstitutionLabelFilterChipUsingSearchFormat
                {...props.label}
                size="medium"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
