import { MedicalInstitutionForSearchResults } from '@/src/api/generated/models/';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Tag } from '@/src/components/foundations/DataDisplays/Tag';
import { Link } from '@/src/components/foundations/Utils/Link';
import { toDisplayFormat } from '@/src/utils/runningStatus';

import styles from './styles.module.scss';

import EventNote from '@mui/icons-material/EventNote';
import LocalPhone from '@mui/icons-material/LocalPhone';
import LocationOn from '@mui/icons-material/LocationOn';

type Props = {
  medicalInstitution: MedicalInstitutionForSearchResults;
  onClick: VoidFunction;
  isTagFeatureAvailable: boolean;
};

export const SearchResultListItemPresenter: React.FC<Props> = (props) => (
  <div className={styles.container}>
    <div>
      {props.isTagFeatureAvailable &&
        props.medicalInstitution.tags &&
        props.medicalInstitution.tags.length > 0 && (
          <ul className={styles.tagsWrapper}>
            {props.medicalInstitution.tags.map((tagName, index) => (
              <li key={index}>
                <Tag text={tagName} />
              </li>
            ))}
          </ul>
        )}
      <div className={styles.medicalInstitutionName}>
        <Link
          href={`/crms/${props.medicalInstitution.id}`}
          isOpenAnotherTab={true}
          onClick={props.onClick}
        >
          {toDisplayFormat(props.medicalInstitution.runningStatus)}
          {props.medicalInstitution.name}
        </Link>
      </div>
      <ul className={styles.itemList}>
        <li className={styles.item}>
          <LocationOn className={styles.icon} />
          <span className={styles.text}>
            {props.medicalInstitution.address}
          </span>
        </li>
        <li className={styles.item}>
          <LocalPhone className={styles.icon} />
          <span className={styles.text}>{props.medicalInstitution.tel}</span>
        </li>
        <li className={styles.item}>
          <EventNote className={styles.icon} />
          <span className={styles.text}>
            {props.medicalInstitution.departments.join('/')}
          </span>
        </li>
      </ul>
    </div>
    <div className={styles.linkButtonWrapper} onClick={props.onClick}>
      <Button
        color="basic"
        isLink={true}
        href={`/crms/${props.medicalInstitution.id}`}
        isOpenAnotherTab={true}
        className={styles.linkButton}
      >
        医療機関詳細を開く
      </Button>
    </div>
  </div>
);
