import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Divider } from '@/src/components/foundations/Layouts/Divider';
import { useResponsive } from '@/src/hooks/useResponsive';

import { SearchResultListItem } from './SearchResultListItem';
import styles from './styles.module.scss';

type Props = {
  totalResult: number;
  medicalInstitutions: React.ComponentProps<
    typeof SearchResultListItem
  >['medicalInstitution'][];
  isTagFeatureAvailable: boolean;
};

export const SearchResultListPresenter: React.FC<Props> = (props) => {
  const responsive = useResponsive();
  return (
    <div className={styles.base}>
      <div className="header">
        <Divider />
        <h3 className={styles.head}>{`検索結果（${props.totalResult}件）`}</h3>
        <Divider />
      </div>
      <div className="contents">
        {props.medicalInstitutions.length > 0 ? (
          <ul className={styles.wrapper}>
            {props.medicalInstitutions.map((l) => (
              <li key={l.id} className={styles.item}>
                <SearchResultListItem
                  medicalInstitution={l}
                  isTagFeatureAvailable={props.isTagFeatureAvailable}
                />
              </li>
            ))}
          </ul>
        ) : (
          <>
            {responsive.isSp ? (
              <div className={styles.noItemWrapper}>
                <h2 className={styles.noItem}>
                  該当する医療機関は見つかりませんでした
                </h2>
              </div>
            ) : (
              <CenteringContainer>
                <h2 className={styles.noItem}>
                  該当する医療機関は見つかりませんでした
                </h2>
              </CenteringContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
};
