import { SearchHeaderPresenter } from './presenter';

import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../form';
import { SearchFilterQuery } from '../reducer';

type Props = {
  form: UseFormReturn<Schema>;
  searchFilterQuery: SearchFilterQuery;
  onSubmit: () => void;
  onChangeFilterQuery: (searchFilterQuery: SearchFilterQuery) => void;
  isTagFeatureAvailable: boolean;
  isMedicalInstitutionLabelFeatureAvailable: boolean;
} & Pick<
  React.ComponentProps<typeof SearchHeaderPresenter>['searchForm'],
  'isDisabled'
>;

export const SearchHeader: React.FC<Props> = (props) => {
  return (
    <SearchHeaderPresenter
      searchForm={{
        form: props.form,
        onSubmit: props.onSubmit,
        isDisabled: props.isDisabled,
      }}
      searchFilter={{
        facilityType: {
          selectedFacilityTypeIds: props.searchFilterQuery.facilityTypeIds,
          onSubmit: (facilityTypeIds) => {
            props.onChangeFilterQuery({
              ...props.searchFilterQuery,
              facilityTypeIds,
            });
          },
        },
        department: {
          selectedDepartmentIds: props.searchFilterQuery.departmentIds,
          onSubmit: (departmentIds) => {
            props.onChangeFilterQuery({
              ...props.searchFilterQuery,
              departmentIds,
            });
          },
        },
        tag: {
          selectedTagIds: props.searchFilterQuery.tagFields.tagIds,
          selectedTagSearchCondition:
            props.searchFilterQuery.tagFields?.tagSearchCondition,
          onSubmit: (tagFields) => {
            props.onChangeFilterQuery({
              ...props.searchFilterQuery,
              tagFields,
            });
          },
        },
        isTagFeatureAvailable: props.isTagFeatureAvailable,
        label: {
          searchConditions: props.searchFilterQuery.labels,
          onSubmit: (labels) => {
            props.onChangeFilterQuery({ ...props.searchFilterQuery, labels });
          },
        },
        isMedicalInstitutionLabelFeatureAvailable:
          props.isMedicalInstitutionLabelFeatureAvailable,
      }}
    />
  );
};
