import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { TopLabelType } from '@/src/components/foundations/DataDisplays/Label/types';
import { ErrorMessages } from '@/src/components/foundations/Forms/ErrorMessages';
import { Input } from '@/src/components/foundations/Forms/Input';

import styles from './styles.module.scss';

import React, { forwardRef } from 'react';

import { Stack } from '../../Layouts/Stack';

type InputProps = React.ComponentProps<typeof Input>;
type LabelComponentProps = Extract<
  React.ComponentProps<typeof Label>,
  TopLabelType
>;

type Props<Name extends string> = Omit<InputProps, 'hasError'> & {
  label: string;
  name: Name;
  description?: string;
  errorMessages: string[];
  distance?: number;
  typography?: LabelComponentProps['typography'];
  tooltip?: LabelComponentProps['tooltip'];
};

const TextFieldContent = forwardRef<HTMLInputElement, Props<string>>(
  (props, ref) => {
    const { errorMessages, ...inputProps } = props;

    const hasError = () => errorMessages.length > 0;

    return (
      <Stack align="stretch" width={props.width}>
        <Label
          text={props.label}
          position="top"
          distance={props.distance ?? 8}
          typography={
            props.typography ?? {
              lineHeight: 'dense',
              fontSize: 14,
              fontWeight: 'normal',
            }
          }
          required={props.required}
          tooltip={props.tooltip ? { ...props.tooltip } : undefined}
        >
          <Input {...inputProps} hasError={hasError()} ref={ref} />
          {props.description && (
            <div className={styles.description}>{props.description}</div>
          )}
        </Label>
        <ErrorMessages messages={errorMessages} />
      </Stack>
    );
  },
);
export const TextField: <Name extends string>(
  props: React.PropsWithoutRef<Props<Name>> &
    React.RefAttributes<HTMLInputElement>,
) => ReturnType<typeof TextFieldContent> = TextFieldContent;
