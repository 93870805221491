import { SearchFilter } from './SearchFilter';
import { SearchForm } from './SearchForm';
import styles from './styles.module.scss';

type Props = {
  searchForm: React.ComponentProps<typeof SearchForm>;
  searchFilter: React.ComponentProps<typeof SearchFilter>;
};

export const SearchHeaderPresenter: React.FC<Props> = (props) => (
  <div className={styles.base}>
    <SearchForm {...props.searchForm} />
    <div className={styles.filter}>
      <SearchFilter {...props.searchFilter} />
    </div>
  </div>
);
