import { Card } from '@/src/components/foundations/DataDisplays/Card';
import { LoadingCircle } from '@/src/components/foundations/Feedback/LoadingCircle';
import { CenteringContainer } from '@/src/components/foundations/Layouts/CenteringContainer';
import { Pagination } from '@/src/components/foundations/Navigations/Pagination';
import { useResponsive } from '@/src/hooks/useResponsive';

import { SearchHeader } from './SearchHeader';
import { SearchResultList } from './SearchResultList';
import styles from './styles.module.scss';

type ContentsInitial = { status: 'initial' };
type ContentsLoading = { status: 'loading' };
type ContentsNormal = {
  status: 'normal';
  searchResultList: React.ComponentProps<typeof SearchResultList>;
  pagination: React.ComponentProps<typeof Pagination>;
};

type Props = {
  searchHeader: React.ComponentProps<typeof SearchHeader>;
  searchContents: ContentsInitial | ContentsLoading | ContentsNormal;
};

export const MedicalInstitutionsSearchPresenter: React.FC<Props> = (props) => {
  const responsive = useResponsive();

  return (
    <div className={styles.cardWrapper}>
      <Card>
        <div className={styles.contentsWrapper}>
          <SearchHeader {...props.searchHeader} />
          <div className="contents">
            {((): JSX.Element => {
              switch (props.searchContents.status) {
                case 'initial':
                  return (
                    <>
                      {responsive.isSp ? (
                        <div className={styles.contentsInner}>
                          <h2 className={styles.initialText}>
                            検索結果はここに表示されます
                          </h2>
                        </div>
                      ) : (
                        <CenteringContainer>
                          <h2 className={styles.initialText}>
                            検索結果はここに表示されます
                          </h2>
                        </CenteringContainer>
                      )}
                    </>
                  );
                case 'loading':
                  return (
                    <div className={styles.contentsInner}>
                      <CenteringContainer>
                        <LoadingCircle />
                      </CenteringContainer>
                    </div>
                  );
                case 'normal':
                  return (
                    <>
                      <SearchResultList
                        {...props.searchContents.searchResultList}
                      />
                      {props.searchContents.pagination.lastPage > 0 && (
                        <div className={styles.paginationWrapper}>
                          <Pagination {...props.searchContents.pagination} />
                        </div>
                      )}
                    </>
                  );
              }
            })()}
          </div>
        </div>
      </Card>
    </div>
  );
};
