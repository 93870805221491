import { CurrentUserFeaturesEnum } from '@/src/api/generated';
import { useAccount } from '@/src/hooks/useAccount';
import { useResponsive } from '@/src/hooks/useResponsive';
import { gaEventClick } from '@/src/utils/gtag';

import { useAction } from './action';
import { useSearchForm } from './form';
import { MedicalInstitutionsSearchPresenter } from './presenter';
import { SearchFilterQuery, actionType, useSearchState } from './reducer';

type PresenterProps = React.ComponentProps<
  typeof MedicalInstitutionsSearchPresenter
>;

export const MedicalInstitutionsSearch: React.FC = () => {
  const { account } = useAccount();
  const responsive = useResponsive();

  const { search } = useAction();
  const { state, dispatch } = useSearchState(
    responsive.isSp ? [] : account.defaultFacilityTypeIds?.map(String) ?? [],
  );
  const { form, searchFormValues, isDisabled } = useSearchForm(
    account.tenant?.prefecture ?? '',
  );

  const searchOnSubmit = (
    page: number,
    searchFilterQuery: SearchFilterQuery,
  ) => {
    const { tagFields, ...filterQuery } = searchFilterQuery;

    dispatch({ type: actionType.setSearchResults, payload: null });
    search({
      request: {
        page,
        ...searchFormValues,
        ...filterQuery,
        tagIds: tagFields.tagIds,
        tagIdsSearchCondition: tagFields.tagSearchCondition,
      },
      successCallback: (res) =>
        dispatch({
          type: actionType.setSearchResults,
          payload: res,
        }),
    });
    gaEventClick('medical-institution-search/form/search-button');
  };

  const searchContentsProps = (): PresenterProps['searchContents'] => {
    if (state.searchResults === undefined) {
      return {
        status: 'initial',
      } as const;
    } else if (state.searchResults === null) {
      return {
        status: 'loading',
      } as const;
    } else {
      return {
        status: 'normal',
        searchResultList: {
          totalResult: state.searchResults.totalCount,
          medicalInstitutions: state.searchResults.medicalInstitutions,
          isTagFeatureAvailable: account.features.includes(
            CurrentUserFeaturesEnum.Tags,
          ),
        },
        pagination: {
          currentPage: state.page,
          lastPage: state.searchResults.totalPage,
          onClickCallback: (page: number) => {
            dispatch({ type: actionType.paginate, payload: page });
            searchOnSubmit(page, state.searchFilterQuery);
          },
        },
      } as const;
    }
  };

  return (
    <MedicalInstitutionsSearchPresenter
      searchHeader={{
        form,
        searchFilterQuery: state.searchFilterQuery,
        onSubmit: () => searchOnSubmit(state.page, state.searchFilterQuery),
        onChangeFilterQuery: (searchFilterQuery: SearchFilterQuery) => {
          dispatch({
            type: actionType.setSearchFilterQuery,
            payload: searchFilterQuery,
          });
          searchOnSubmit(state.page, searchFilterQuery);
        },
        isDisabled: isDisabled || state.searchResults === null,
        isTagFeatureAvailable: account.features.includes(
          CurrentUserFeaturesEnum.Tags,
        ),
        isMedicalInstitutionLabelFeatureAvailable: account.features.includes(
          CurrentUserFeaturesEnum.MedicalInstitutionLabels,
        ),
      }}
      searchContents={searchContentsProps()}
    />
  );
};
