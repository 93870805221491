import {
  Button,
  ButtonProps,
} from '@/src/components/foundations/Buttons/Button';

import styles from './styles.module.scss';

import Search from '@mui/icons-material/Search';
import { Fragment } from 'react';

type Props = {
  inputs: { width: number; body: React.ReactNode }[];
  button: Pick<
    Exclude<ButtonProps, { isLink: boolean }>,
    'isDisabled' | 'onClick'
  >;
};

export const SearchFormBasePresenter: React.FC<Props> = (props) => (
  <form className={styles.base}>
    <div className={styles.searchTextFieldsContainer}>
      {props.inputs.map((input, index) => (
        <Fragment key={index}>
          <div style={{ width: input.width }}>{input.body}</div>
          {index !== props.inputs.length - 1 && (
            <div className={styles.verticalLine} />
          )}
        </Fragment>
      ))}
    </div>
    <Button
      color="primary"
      icon={{ body: <Search />, position: 'left' }}
      {...props.button}
    >
      検索
    </Button>
  </form>
);
