import {
  Button,
  ButtonProps,
} from '@/src/components/foundations/Buttons/Button';

import styles from './spStyles.module.scss';

import { Fragment } from 'react';

import { Search } from '../../foundations/DesignToken/Icons';

type Props = {
  inputs: {
    width: number; // inputs アイテム間の横幅の割合に変換するための数値
    body: React.ReactNode;
  }[];
  button: Pick<
    Exclude<ButtonProps, { isLink: boolean }>,
    'isDisabled' | 'onClick'
  >;
};

/**
 * @deprecated SearchFormBase を利用しているコンポーネントで、ここの SP用のコードを参照しているところはない。実質廃止されている
 */
export const SpSearchFormBasePresenter: React.FC<Props> = (props) => {
  const allInputWidths = props.inputs.reduce(
    (acc, input) => acc + input.width,
    0,
  );

  const widthRateFor = (inputWidth: number): number => {
    return (inputWidth / allInputWidths) * 100;
  };

  return (
    <form className={styles.base}>
      <div className={styles.searchTextFieldsContainer}>
        {props.inputs.map((input, index) => (
          <Fragment key={index}>
            <div
              style={{ width: `${widthRateFor(input.width)}%` }}
              className={styles.input}
            >
              {input.body}
            </div>
            {index !== props.inputs.length - 1 && (
              <div className={styles.verticalLine} />
            )}
          </Fragment>
        ))}
      </div>
      <Button
        type="iconOnly"
        size="medium"
        onClick={props.button.onClick}
        icon={<Search />}
      />
    </form>
  );
};
