import { useResponsive } from '@/src/hooks/useResponsive';

import { SearchFormBasePresenter } from './presenter';
import { SpSearchFormBasePresenter } from './spPresenter';

type PresenterProps = React.ComponentProps<typeof SearchFormBasePresenter>;
type SpPresenterProps = React.ComponentProps<typeof SpSearchFormBasePresenter>;
type Props = PresenterProps & SpPresenterProps;

export const SearchFormBase: React.FC<Props> = (props) => {
  const responsive = useResponsive();

  return responsive.isSp ? (
    <SpSearchFormBasePresenter {...props} />
  ) : (
    <SearchFormBasePresenter {...props} />
  );
};
