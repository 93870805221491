import {
  IdsSearchCondition,
  LabelSearchCondition,
  PostMedicalInstitutionSearchResponse,
} from '@/src/api/generated';

import { useReducer } from 'react';

const INITIAL_PAGE = 1;
const INITIAL_SEARCH_CONDITION = {
  facilityTypeIds: [],
  departmentIds: [],
  tagFields: {
    tagIds: [],
    tagSearchCondition: IdsSearchCondition.Or,
  },
  labels: [],
};

export const actionType = {
  paginate: 'paginate',
  setSearchFilterQuery: 'setSearchFilterQuery',
  setSearchResults: 'setSearchResults',
} as const;

export type SearchFilterQuery = {
  facilityTypeIds: string[];
  departmentIds: string[];
  tagFields: TagFieldsType;
  labels: LabelSearchCondition[];
};
type TagFieldsType = {
  tagIds: string[];
  tagSearchCondition: IdsSearchCondition;
};

export type Action =
  | { type: typeof actionType.paginate; payload: number }
  | { type: typeof actionType.setSearchFilterQuery; payload: SearchFilterQuery }
  | {
      type: typeof actionType.setSearchResults;
      payload: PostMedicalInstitutionSearchResponse | null;
    };

export type State = {
  page: number;
  searchFilterQuery: SearchFilterQuery;
  searchResults: PostMedicalInstitutionSearchResponse | null | undefined;
};

const createInitialState = (defaultFacilityTypeIds: string[]): State => ({
  page: INITIAL_PAGE,
  searchFilterQuery: {
    ...INITIAL_SEARCH_CONDITION,
    facilityTypeIds: defaultFacilityTypeIds,
  },
  searchResults: undefined,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionType.paginate:
      return {
        page: action.payload,
        searchFilterQuery: state.searchFilterQuery,
        searchResults: state.searchResults,
      };
    case actionType.setSearchFilterQuery:
      return {
        page: state.page,
        searchFilterQuery: action.payload,
        searchResults: state.searchResults,
      };
    case actionType.setSearchResults:
      return {
        page: state.page,
        searchFilterQuery: state.searchFilterQuery,
        searchResults: action.payload,
      };
    default:
      return state;
  }
};

export const useSearchState = (defaultFacilityTypeIds: string[]) => {
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(defaultFacilityTypeIds),
  );
  return { state, dispatch };
};
