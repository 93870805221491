import { medicalInstitutionsApi } from '@/src/api';
import {
  PostMedicalInstitutionSearchRequest,
  PostMedicalInstitutionSearchResponse,
} from '@/src/api/generated';
import { useToast } from '@/src/hooks/useToast';

export const useAction = () => {
  const { showToast } = useToast();

  const search = async (props: {
    request: PostMedicalInstitutionSearchRequest;
    successCallback: (response: PostMedicalInstitutionSearchResponse) => void;
  }) => {
    try {
      const res = await medicalInstitutionsApi.postMedicalInstitutionSearch({
        postMedicalInstitutionSearchRequest: props.request,
      });
      props.successCallback(res);
    } catch (e) {
      showToast('error', '医療機関の検索に失敗しました');
    }
  };
  return { search };
};
