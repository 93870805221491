import { PrefectureSearchTextField } from '@/src/components/blocks/PrefectureSearchTextField';
import { SearchFormBase } from '@/src/components/blocks/SearchFormBase';
import { SearchTextField } from '@/src/components/blocks/SearchTextField';
import { FormProps } from '@/src/types/form';

import type { SearchFormInputMap } from '.';

type Props = {
  form: {
    prefecture: {
      value: SearchFormInputMap['prefecture'];
      setValue: (value: SearchFormInputMap['prefecture']) => void;
    };
  } & FormProps<SearchFormInputMap>;
};

export const SearchFormPresenter: React.FC<Props> = (props) => (
  <SearchFormBase
    inputs={[
      {
        width: 193,
        body: (
          <SearchTextField
            label="医療機関名"
            placeholder="医療機関名"
            {...props.form.medicalInstitutionName}
          />
        ),
      },
      {
        width: 126,
        body: (
          <SearchTextField
            label="院長名"
            placeholder="院長名"
            {...props.form.administratorName}
          />
        ),
      },
      {
        width: 174,
        body: (
          <PrefectureSearchTextField
            optionIdPrefix="prefecture"
            mode="inline"
            {...props.form.prefecture}
          />
        ),
      },
      {
        width: 234,
        body: (
          <SearchTextField
            label="地域"
            placeholder="住所、地域"
            {...props.form.area}
          />
        ),
      },
    ]}
    button={{
      isDisabled: props.form.isDisabled,
      onClick: props.form.onSubmit,
    }}
  />
);
