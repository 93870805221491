import { Button } from '@/src/components/foundations/Buttons/Button';
import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { TextField } from '@/src/components/foundations/Forms/TextField';
import { Accordion } from '@/src/components/foundations/Utils/Accordion';
import { FormProps } from '@/src/types/form';
import { prefectures } from '@/src/utils/prefectures';

import type { SearchFormInputMap } from '.';
import styles from './spStyles.module.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Search from '@mui/icons-material/Search';
import { useState } from 'react';

type Props = {
  form: FormProps<
    Pick<SearchFormInputMap, 'medicalInstitutionName' | 'area' | 'prefecture'>
  >;
};

export const SpSearchFormPresenter: React.FC<Props> = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        className={styles.trigger}
      >
        <span className={styles.title}>検索条件設定</span>
        <span className={styles.icon}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </span>
      </div>
      <Accordion isExpanded={isExpanded}>
        <form className={styles.contents}>
          <TextField
            label="医療機関名"
            placeholder="入力してください"
            {...props.form.medicalInstitutionName}
            errorMessages={[]}
          />
          <Label
            text="都道府県"
            position="top"
            distance={8}
            typography={{
              lineHeight: 'dense',
              fontSize: 14,
              fontWeight: 'normal',
            }}
          >
            <SelectBox
              {...props.form.prefecture}
              options={prefectures.map(({ name }) => ({
                label: name,
                value: name,
              }))}
              placeHolderDisabled={false}
            />
          </Label>
          <TextField
            label="地域"
            placeholder="入力してください"
            {...props.form.area}
            errorMessages={[]}
          />
          <Button
            color="primary"
            icon={{ body: <Search />, position: 'left' }}
            isDisabled={props.form.isDisabled}
            onClick={(e) => {
              props.form.onSubmit(e);
              setIsExpanded(false);
            }}
          >
            検索
          </Button>
        </form>
      </Accordion>
    </>
  );
};
