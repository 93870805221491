import { gaEventClick } from '@/src/utils/gtag';

import { SearchResultListItemPresenter } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof SearchResultListItemPresenter>,
  'medicalInstitution' | 'isTagFeatureAvailable'
>;

export const SearchResultListItem: React.FC<Props> = (props) => {
  const onClick = () => {
    gaEventClick('hospitals/result/go-to-hospitals');
  };

  return <SearchResultListItemPresenter {...props} onClick={onClick} />;
};
