import { useForm } from '@/src/hooks/useForm';
import { prefectures } from '@/src/utils/prefectures';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  medicalInstitutionName: z.string(),
  administratorName: z.string(),
  prefecture: z.string(),
  area: z.string(),
});
export type Schema = z.infer<typeof schema>;

export const INITIAL_VALUES: Schema = {
  medicalInstitutionName: '',
  administratorName: '',
  prefecture: '',
  area: '',
};

export const useSearchForm = (prefecture: string) => {
  const { form } = useForm(schema, {
    shouldUnregister: false,
    defaultValues: {
      ...INITIAL_VALUES,
      prefecture,
    },
    resolver: zodResolver(schema),
  });
  const inputMap = form.watch();

  const isDisabled = () => {
    const isSomeInputsInput = Object.values(inputMap).some(
      (value) => value !== '',
    );
    const isPrefectureInvalid =
      inputMap.prefecture !== '' &&
      !prefectures.some(({ name }) => name === inputMap.prefecture);

    return !isSomeInputsInput || isPrefectureInvalid;
  };

  return { form, searchFormValues: { ...inputMap }, isDisabled: isDisabled() };
};
