import { useResponsive } from '@/src/hooks/useResponsive';

import { SearchFormPresenter } from './presenter';
import { SpSearchFormPresenter } from './spPresenter';

import { UseFormReturn } from 'react-hook-form';

import { Schema } from '../../form';

export type SearchFormInputMap = {
  medicalInstitutionName: string;
  administratorName: string;
  prefecture: string;
  area: string;
};

type Props = {
  form: UseFormReturn<Schema>;
  onSubmit: VoidFunction;
  isDisabled: boolean;
};

export const SearchForm: React.FC<Props> = (props) => {
  const responsive = useResponsive();

  const inputMap = props.form.watch();
  const setPrefecture = (value: string) => {
    props.form.setValue('prefecture', value);
  };

  const presenterProps = {
    form: {
      medicalInstitutionName: {
        ...props.form.register('medicalInstitutionName'),
        errorMessages: [],
      },
      administratorName: {
        ...props.form.register('administratorName'),
        errorMessages: [],
      },
      prefecture: {
        ...props.form.register('prefecture'),
        errorMessages: [],
        value: inputMap.prefecture,
        setValue: setPrefecture,
      },
      area: {
        ...props.form.register('area'),
        errorMessages: [],
      },
      isDisabled: props.isDisabled,
      onSubmit: props.onSubmit,
    },
  };

  return responsive.isSp ? (
    <SpSearchFormPresenter {...presenterProps} />
  ) : (
    <SearchFormPresenter {...presenterProps} />
  );
};
